import data from "../../.sourceflow/specialisms.json";
import CollectionHelper from "@/helpers/CollectionHelper";

class SpecialismsCollectionHelper extends CollectionHelper {
  fetch({ limit = null, onlyParent = false, parent = false, exclude = null, filter = null } = {}) {
    // let items = sortByDate(this.collection.getItems(), "publish_date");
    let items = this.collection.getItems();

    if (filter) {
      items = items.filter(filter);
    }

    if (onlyParent) {
      items = items.filter((i) => i.parent.id == null);
    }

    if (parent) {
      items = items.filter((i) => i.parent.id === parent);
    }

    if (exclude) {
      items = items.filter((i) => !exclude.includes(i.id));
    }

    if (limit) {
      items = items.slice(0, limit);
    }

    return items;
  }
}

export const specialisms_helper = new SpecialismsCollectionHelper(data);
