import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { home_tiles_helper } from "@/helpers/home_tiles_helper";
import { specialisms_helper } from "@/helpers/specialisms_helper";
import { blog_helper } from "@/helpers/blog_helper";
import { brands_helper } from "@/helpers/brands_helper";
import { getGlobal } from "@/functions/getGlobal";
import Head from "next/head";

export default function Page({ content }) {
  return (
    <>
      <Head>
        {/* <meta property="og:title" content={job.title} /> */}
        {/* <meta property="og:url" content={`/jobs/${job.url_slug}`} /> */}
        {/* <meta name="twitter:title" content={job.title} /> */}
        <meta name="google-site-verification" content="-2P4YbyBV3ixd7cCWGzpJ7u_-jykH82z4ncCglF0mrI" />
        <meta
          name="image"
          property="og:image"
          content="https://cdn.sourceflow.co.uk/lnbos2zk07150v2300f4ztml76su"
        />
        <meta
          name="twitter:image"
          content="https://cdn.sourceflow.co.uk/lnbos2zk07150v2300f4ztml76su"
        />
        <meta name="twitter:title" content="Global Recruitment"></meta>
      </Head>
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({}) {
  const global = getGlobal();
  
  return {
    props: {
      meta: {
        title: createTitle("Home"),
      },
      content: [
        {
          component: "HomeHero",
          props: {
            title: global["page.home.component.Hero.title"] || "TEST",
            title2: global["page.home.component.Hero.title2"] || "TEST",
            boldTitle: {
              path: "page.home.component.Hero.boldTitle",
              placeholder: "Subtitle",
            },
            description: {
              path: "page.home.component.Hero.description",
              placeholder: "We attract the talent that is helping to shape the new global economy.",
            },
            video: "/homepage-videos/xcede_homepage_video.mov",
            className: "text-white"
          },
        },
        {
          component: "HomeTiles",
          props: {
            items: home_tiles_helper.fetch(),
          }
        },
        {
          component: "Testimonials",
          props: {}
        },
        {
          component: "OurBrands",
          props: {
            title: {
              path: "page.home.component.brands.title",
              placeholder: "Title",
            },
            description: {
              path: "page.home.component.brands.description",
              placeholder: "Personalised relationships, Sector specialisms, Global presence",
            },
            brands: brands_helper.fetch(),
          },
        },
       
        {
          component: "LatestBlogs",
          props: {
            title: "LATEST NEWS & INSIGHTS",
            newsTitle: { path: "page.home.latest-news.Header.title", placeholder: "WHAT'S NEW ON" },
            variant: "quaternary",
          },
        },
      ],
    },
  };
}
